<script lang="ts" setup>
import { useField } from 'vee-validate'
import * as yup from 'yup'
import { useCheckToken } from '~/composables/token'

definePageMeta({
  title: 'home.title',
  layout: 'landing'
})
const localePath = useLocalePath()

onMounted(() => {
  if (useUser().value) {
    navigateTo(localePath('/repair/step/1'))
  }
})

const {
  errorMessage,
  value,
  validate: validateInput
} = useField('repairCode', yup.string().required('home.coupon.required').min(20, 'home.coupon.length'), {
  validateOnValueUpdate: false
})

async function submit () {
  await validateInput()
  if (errorMessage.value) {
    console.log('error' + errorMessage.value)
    return
  }
  const res = await useCheckToken(value.value as string)
  if (res.success) {
    navigateTo(localePath('/repair/step/1?token=' + value.value))
    return
  }
  // @ts-ignore
  errorModal.showModal()
}
</script>

<template>
  <div class="grid justify-items-center mt-40 w-full">
    <p class="text-5xl font-bold text-center">
      {{ $t('home.welcome') }}
    </p>
    <div class="grid justify-items-center mt-8 gap-2">
      <p class="text-xl">
        {{ $t('home.repairCode') }}
      </p>
      <div class="grid justify-items-center">
        <div class="flex flex-col">
          <input
            v-model="value"
            type="text"
            class="input input-bordered"
            :placeholder="$t('home.repairCodePlaceholder')"
          >
          <p v-if="errorMessage" class="label-text-alt font-semibold text-red-500 text-center">
            {{ $t(errorMessage) }}
          </p>
        </div>
        <button class="btn w-2/3 btn-primary mt-4" @click="submit">
          {{ $t('home.repairCodeButton') }}
        </button>
      </div>
    </div>
    <dialog id="errorModal" class="modal">
      <div class="modal-box">
        <h3 class="font-bold text-lg">
          {{ $t('home.errorModalTitle') }}
        </h3>
        <p class="py-4">
          {{ $t('home.errorModalText') }}
        </p>
        <div class="modal-action">
          <form method="dialog">
            <!-- if there is a button in form, it will close the modal -->
            <button class="btn btn-primary">
              {{ $t('home.errorModalButton') }}
            </button>
          </form>
        </div>
      </div>
      <form method="dialog" class="modal-backdrop">
        <button>
          {{ $t('home.errorModalButton') }}
        </button>
      </form>
    </dialog>
  </div>
</template>
